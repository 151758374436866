import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import Loading from '@/components/widgets/Loading.vue'
import Delete from '@/components/alerts/sweet-alert/alertDelete.vue'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
import moment from "moment"

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    Delete,
    DatePicker,
    Paginate
  },
  data: function () {
    return {
      sum: {
        all: 0,
        month: 0,
        year: 0
      },
      keyword: "",
      keyword_status: null,
      keyword_startdate: "",
      keyword_enddate: "",
      keyword_start_shipdate: "",
      keyword_end_shipdate: "",
      role: "",
      branch: "",
      loading: false,
      advanced: false,
      status: null,
      created: {
        start: null,
        end: null
      },
      duedate: {
        start: null,
        end: null
      },
      delivery: {
        start: null,
        end: null
      },
      orderStatus: [
        { text: 'On hold', value: 'onhold' },
        { text: 'รออนุมัติ', value: 'wait-approve' },
        { text: 'อนุมัติแล้ว', value: 'approved' },
        { text: 'ไม่อนุมัติ', value: 'not-approved' },
        { text: 'สำเร็จ', value: 'success' }
      ],
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        {
          key: 'document_no',
          label: 'เลขที่รายการซื้อ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'document_date',
          label: 'วันที่ออกเอกสาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'purchase_date',
          label: 'วันที่สั่งซื้อ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'description',
          label: 'ชื่อรายการ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'grandtotal',
          label: 'จำนวนเงิน',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'creator_detail.name',
          label: 'ผู้จัดทำ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'status',
          label: 'สถานะ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'action',
          label: '#',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right'
        }
      ],
      items: [
        // {
        //   _id: '123456',
        //   doc_no: 'PDRY67090001',
        //   doc_date: new Date(),
        //   so_code: 'IVKR6709-00001',
        //   so_date: new Date(),
        //   emp: 'กชมน',
        //   approved: 'มนชิรา',
        //   type: 'แผ่น',
        //   status: '<label class="badge badge-info">On hold</label>'
        // }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },

  methods: {
    async getRole() {
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
    async onInitData(currentPage) {
      this.currentPage = currentPage;
      this.busy = true;
      this.apiStatus = "กำลังค้นหาข้อมูล..";
      this.items = [];
      try {
        const page = currentPage ? currentPage : 1;
        const start = this.keyword_start ? moment(this.keyword_start).format('YYYY-MM-DD') : ''
        const end = this.keyword_end ? moment(this.keyword_end).format('YYYY-MM-DD') : ''
        const branch = this.keyword_branch ? this.keyword_branch : ''
        const result = await Vue.prototype.$axios.get(
          `${Vue.prototype.$host}/api/v1/document/purchases?page=${page}&keyword=${this.keyword}&keyword_status=${this.keyword_status ? this.keyword_status : ''}&keyword_startdate=${this.keyword_startdate ? moment(this.keyword_startdate).format('YYYY-MM-DD') : ''}&keyword_enddate=${this.keyword_enddate ? moment(this.keyword_enddate).format('YYYY-MM-DD') : ''}&keyword_start_shipdate=${this.keyword_start_shipdate ? moment(this.keyword_start_shipdate).format('YYYY-MM-DD') : ''}&keyword_end_shipdate=${this.keyword_end_shipdate ? moment(this.keyword_end_shipdate).format('YYYY-MM-DD') : ''}&keyword_branch=${branch}`
        );
        this.busy = false;

        this.apiStatus = "ไม่พบข้อมูล";
        this.currentData = result.data;

        this.items = result.data.data.data;

        this.sum.all = result.data.sum
        this.sum.month = result.data.sum_month
        this.sum.year = result.data.sum_year

        this.totalItem = result.data.data.total;
      } catch (error) {
        this.apiStatus = "ไม่พบข้อมูล";
        this.onExceptionHandler(error.response.data.message);
        this.busy = false;
        return [];
      }

      // this.loading = true
      // setTimeout(() => {
      //   this.loading = false
      // }, 1000)
    },
    async generateProductionDocument(uuid) {
      try {
        this.loading = true
        const result = await this.$axios.get(
          this.$host + "/api/v1/downloadProductionPDF/" + uuid,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob"
          }
        )
        const blob = new Blob([result.data], { type: 'application/pdf' });
        const objectUrl = URL.createObjectURL(blob)
        window.open(objectUrl, 'targetWindow',
          `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=SomeSize,
          height=SomeSize`);

        this.loading = false
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onCreate(){
      this.$router.push("/quotation/create");
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.onInitData(this.currentPage);
    });
    this.getRole()
  },
}
